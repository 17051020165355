import * as React from "react";
import FormAutoReplyEmailerCodeTemplate from './codeTemplate'
import FormAutoReplyEmailerHtmlEmailTemplate from './emailTemplate'
import CodePreview from "../../../components/codePreview";
import SidebarAd from "../../../components/adUnits/sidebarAd"

function FormAutoReplyEmailerToolForm() {
    const [code, setCode] = React.useState(0)
    const [emailFieldName, setEmailFieldName] = React.useState('What\'s Your Email?')
    const [submitterNameField, setSubmitterNameField] = React.useState('What\'s Your Name?')
    const [replyToEmail, setReplyToEmail] = React.useState('noreply@bootstrapping.tools')
    const [noReply, setNoReply] = React.useState(false)
    const [emailSubject, setEmailSubject] = React.useState('Thank you for your form submission')
    const [emailBody, setEmailBody] = React.useState('Tempor officia dolor eiusmod id nostrud nulla. Incididunt amet irure cupidatat eu anim. Do in Lorem laborum nulla ea veniam. Sunt voluptate labore nostrud labore pariatur aliqua incididunt. Velit eiusmod non laborum nisi eu nisi irure dolor fugiat excepteur do consequat. Et officia culpa adipisicing nostrud ipsum aliquip ut culpa velit.')
    const [companyLogoUrl, setCompanyLogoUrl] = React.useState('https://placeholderlogo.com/img/placeholder-logo-1.png')
    const [companyName, setCompanyName] = React.useState('Your Awesome Company')
    const [companyWebsiteUrl, setCompanyWebsiteUrl] = React.useState('https://yourawesomecompany.com')

    
    React.useEffect(() => {
        let obj = {
            emailFieldName: emailFieldName,
            submitterNameField: submitterNameField,
            replyToEmail: replyToEmail,
            noReply: noReply,
            emailSubject: emailSubject,
            emailBody: emailBody,
            companyLogoUrl: companyLogoUrl,
            companyName: companyName,
            companyWebsiteUrl: companyWebsiteUrl
        }
        setCode( (code) => code = FormAutoReplyEmailerCodeTemplate(obj))
    });
    return (
        <div className="container">
            <hr />
            <div className="columns is-multiline">
                <div className="column is-8">
                <div className="columns is-multiline">
                        <div className="column is-4">
                            <div className="field">
                                <label className="label">Company Name</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="logoUrl"
                                        placeholder="Your Awesome Company"
                                        onChange={(e) => setCompanyName(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>(leave blank if none)</p>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="field">
                                <label className="label">Company Website Url</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="logoUrl"
                                        placeholder="https://yourawesomecompany.com"
                                        onChange={(e) => setCompanyWebsiteUrl(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>(leave blank if none)</p>
                            </div>
                        </div>
                        <div className="column is-4">
                            <div className="field">
                                <label className="label">Company Logo Url</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="logoUrl"
                                        placeholder="https://placeholderlogo.com/img/placeholder-logo-1.png"
                                        onChange={(e) => setCompanyLogoUrl(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>(leave blank if none)</p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="columns is-multiline">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Recipient Email Field Name</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="emailFieldName"
                                        placeholder="Email Address Field"
                                        onChange={(e) => setEmailFieldName(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>What is the fieldname for the recipient's email address?<br />(Not their email address)</p>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Recipient Name Field Name</label>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text" 
                                        name="recipientName"
                                        placeholder="Full Name Field"
                                        onChange={(e) => setSubmitterNameField(e.target.value)}
                                    />
                                </div>
                                <p className="is-size-7" style={{marginTop: "0.5rem"}}>What is the fieldname for the recipient's name?</p>
                            </div>
                        </div>
                        <div className="column is-6">
                            <label className="label">ReplyTo Email</label>
                            <div className="columns is-multiline is-gapless">
                                <div className="column is-8">
                                    <div className="field">
                                        <div className="control" style={{marginRight: "0.5rem"}}>
                                            { noReply == true ? 
                                            <input 
                                                className="input" 
                                                type="text" 
                                                name="replyTo"
                                                value=""
                                                disabled
                                            />
                                            :
                                            <input 
                                                className="input" 
                                                type="text" 
                                                name="replyTo"
                                                placeholder="replyTo@example.com"
                                                onChange={(e) => setReplyToEmail(e.target.value)}
                                            /> 
                                            }
                                        </div>
                                        <p className="is-size-7" style={{marginTop: "0.5rem"}}>Is there a ReplyTo email you'd like to specify?</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <div className="control" style={{margin: "1rem 0rem"}}>
                                            <label className="checkbox">
                                                <input 
                                                    type="checkbox"
                                                    name="noReply"
                                                    onClick={(e) => {setNoReply(!noReply); setReplyToEmail('')}}
                                                />
                                                &nbsp;No Reply
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <SidebarAd />
                </div>
            </div>
            <hr />
            <div className="columns is-multiline">
                <div className="column is-10">
                    <div className="field">
                        <label className="label">Email Subject</label>
                        <div className="control" style={{marginLeft: "1rem"}}>
                            <input 
                                className="input" 
                                type="text" 
                                name="emailSubject"
                                placeholder="Thanks For Your Submission"
                                onChange={(e) => setEmailSubject(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Email Body</label>
                        <div className="control" style={{marginLeft: "1rem"}}>
                            <textarea 
                                className="textarea" 
                                type="textarea" 
                                name="emailSubject"
                                placeholder="Tempor officia dolor eiusmod id nostrud nulla. Incididunt amet irure cupidatat eu anim. Do in Lorem laborum nulla ea veniam. Sunt voluptate labore nostrud labore pariatur aliqua incididunt. Velit eiusmod non laborum nisi eu nisi irure dolor fugiat excepteur do consequat. Et officia culpa adipisicing nostrud ipsum aliquip ut culpa velit."
                                onChange={(e) => setEmailBody(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div className="columns is-multiline mb-5 pb-5">
                <div className="column" id="generatedCodeBlock">
                    <h1 className="code-preview-name">Code.gs</h1>
                    <CodePreview code={code} />
                </div>
            </div>
            <hr />
            <div className="columns is-multiline mb-5 pb-5">
                <div className="column" id="generatedCodeBlock">
                    <h1 className="code-preview-name">emailTemplate.html</h1>
                    <CodePreview code={FormAutoReplyEmailerHtmlEmailTemplate()} />
                </div>
            </div>
        </div>
    )
}

export default FormAutoReplyEmailerToolForm;