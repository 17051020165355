import React from "react";
import PropTypes from "prop-types";
import headerbg from "../img/banners/headerbg.png";

export default function ContentPageHeader(props) {
  const {
    height = 600,
    img,
    imgPosition = "top left",
    title
  } = props;

  return (
    <React.Fragment>
      <div className="page-header margin-top-0 has-text-white is-info">
        <div className="container">
          <section className="hero">
            <div className="hero-body">
              <h1 
                className="title has-text-centered is-size-1-desktop is-size-3-touch is-italic"
                style={{
                  paddingTop: "3.5rem"
                }}
              >
                {title}
              </h1>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

ContentPageHeader.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  height: PropTypes.number,
  title: PropTypes.string
};
