import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContentPageHeader from "../components/ContentPageHeader"
import FormAutoReplyEmailerToolForm from "../pages/tools/forms-autoreply-emailer/codeForm";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const InstructionsTemplate = ({ title, content, contentComponent }) => {
  const Instructions = contentComponent || Content;
  
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <Instructions className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

InstructionsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};


const FormAutoReplyEmailerToolPage = ({ data }) => {
  const { markdownRemark: post } = data;
    
    return (
        <Layout>
          <ContentPageHeader title="Google Forms Auto-reply Emailer Tool" />
          <InstructionsTemplate
            contentComponent={HTMLContent}
            title={post.frontmatter.title}
            content={post.html}
          />
          <FormAutoReplyEmailerToolForm />
        </Layout>
    )
}

FormAutoReplyEmailerToolPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FormAutoReplyEmailerToolPage

export const instructionsQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
