function FormAutoReplyEmailerCodeTemplate(obj) {
    let emailCol = obj.emailFieldName !== undefined ? JSON.parse(JSON.stringify(obj.emailFieldName)) : ''
    let nameCol = obj.submitterNameField !== undefined ? JSON.parse(JSON.stringify(obj.submitterNameField)) : ''
    return (
        `
function settings() {
    return {
        emailColumn: "${emailCol}",
        nameColumn: "${nameCol}",
        replyToEmail: '${obj.replyToEmail}',
        noReply: '${obj.noReply}',
        companyName: '${obj.companyName}',
        websiteUrl: '${obj.companyWebsiteUrl}',
        logoUrl: '${obj.companyLogoUrl}',
        subject: ${JSON.stringify(obj.emailSubject)},
        message: ${JSON.stringify(obj.emailBody)}
    }
}

function initalSetup() {
    ScriptApp.newTrigger('sendAutoReplyEmail')
        .forSpreadsheet(SpreadsheetApp.getActiveSpreadsheet())
        .onEdit()
        .create()
}

function sendAutoReplyEmail(e) {
    let namedValues = e.namedValues
    let recipientEmailAddress = namedValues[settings().emailColumn].toString()
    let recipientName = namedValues[settings().nameColumn].toString()
    let htmlTemplate = HtmlService.createTemplateFromFile('emailTemplate')

    htmlTemplate.data = {
        recipientEmail: recipientEmailAddress,
        recipientName: recipientName,
        body: settings().message,
        logo: settings().logoUrl,
        url: settings().websiteUrl,
        companyName: settings().companyName,
    }

    GmailApp.sendEmail(recipientEmailAddress, settings().subject, htmlTemplate.evaluate().getContent(), {
        htmlBody: htmlTemplate.evaluate().getContent(),
        noReply: true,
        replyTo: settings().replyToEmail
    })
}
`
    )
}

export default FormAutoReplyEmailerCodeTemplate